import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PisoParameters } from '@common/models/piso/piso-parameters.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PisoParametersService {

  public static pisoParameterCollectionName = 'PISO_PARAMETROS';
  public static pisoParameterDocName = 'DATOS';

  constructor(
    private af: AngularFirestore
  ) { }

  getFinEndObjetivos(): Observable<PisoParameters> {
    return this.af.doc<PisoParameters>(`${PisoParametersService.pisoParameterCollectionName}/${PisoParametersService.pisoParameterDocName}`)
      .get()
      .pipe(
        map(snapshot => snapshot.data())
      );
  }
}
