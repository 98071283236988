import { Injectable } from '@angular/core';
import { AngularFirestore,Query } from '@angular/fire/firestore';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { TiersProductsImport } from '@common/interfaces/tiers-products-import.interface';
import { PromocionesForm } from '@common/interfaces/promocionesForm.interface';
import { firestore } from 'firebase/app';
import { Center } from '@common/interfaces/center.interface';
import { Stand } from '@common/interfaces/stand.interface';
import { Brand } from '@common/interfaces/brand.interface';
import { Promocion } from '@common/models/promociones.model';
import { Product } from '@common/models/product.interface';
import { ProductsPP } from '@common/models/products-pp.model';

@Injectable({
  providedIn: 'root'
})
export class PromocionesFormService {
  public static readonly CsvServiceCollectionName = 'PROMOS_PUNTOS';
  private readonly collectionName = 'PROMOS_PUNTOS';
  private readonly articlesCollectionName = 'ARTICULOS';
  private readonly articlesPPCollectionName = 'ARTICULOS_PP';


  public centros: String[] = [];
  public articulos: String[] = [];
  itemCollectionID: any;
  public formpromo: Partial<PromocionesForm[]>
  public brand: number = null;
  public center: number[] = [];
  public myEventList: Observable<TiersProductsImport[]>;
  subscription$: SubscriptionLike[] = [];
  subscriptionpromo$: Subscription;
  constructor(
    private af: AngularFirestore,
  ) { }

  ngOnDestroy(): void {
    this.subscriptionpromo$.unsubscribe();
    this.subscription$.forEach(sub => sub.unsubscribe());
  }

  public getPromociones(startDate: Date, endDate: Date, promocionesFilter?: {center?: Center, stand?: Stand, brand?: Brand,}): Observable<Promocion[]> {
    let today = firestore.Timestamp.fromDate(new Date());
    console.log(today)
    return this.af.collection<Promocion>(
      this.collectionName,
      (query: Query) => {
          query = query.where('FECHA_INICIO_PP', '>=', startDate).where('FECHA_INICIO_PP', '<', endDate);
        return query;
      }
    ).valueChanges().pipe(
      map(
        promociones => promociones.map(
          a => 
          ({
            ...a,
          }),
          promociones.forEach(promo => 
            { 
              this.PromoMapper(promo)
            })
        )
      )
    );
  }

  public PromoMapper(promociones: Promocion): Observable<Promocion> {

    if (promociones.TOTAL_TICKET == true) {
      promociones.TIPO_PROMOCION = 'Venta'
    } else if (promociones.TOTAL_TICKET == false) {
      promociones.TIPO_PROMOCION = 'Selección'
    }
    let today = firestore.Timestamp.fromDate(new Date());
    if (promociones.FECHA_FIN_PP >= today) {
      promociones.ESTADO = 'Activa';
    } else {
      promociones.ESTADO = 'Finalizada';
    }

    promociones.PUNTOS_EURO = Number(promociones.PUNTOS_EURO*100);
    
    promociones.FECHA_INICIO = (promociones.FECHA_INICIO_PP as unknown as firestore.Timestamp)?.toDate();
    promociones.FECHA_FIN = (promociones.FECHA_FIN_PP as unknown as firestore.Timestamp)?.toDate();

    return 
  }

  public async getPromotionArticles(promotion: Promocion): Promise<{CODIGO_EAN:string, ID_PP:number}[]> {
    console.log("promotion => ", promotion)

    const snapshot = await this.af.collection<ProductsPP>(
      this.articlesPPCollectionName,
      (query: Query) => {
          query = query.where('ID_PP', '==', promotion.ID_PP);
        return query;
      }
    ).get().toPromise();

    const articlesPPData = snapshot.docs.map(doc => doc.data());

    return articlesPPData.map(({ CODIGO_EAN, ID_PP }) => ({ CODIGO_EAN, ID_PP }));
  }

  public async importPromotionArticles(articlesPPData:{CODIGO_EAN:string, ID_PP:number}[]){
    const snapshot = await this.af.collection<ProductsPP>(
      this.articlesPPCollectionName,
      (query: Query) => {
          query = query.where('ID_PP', '==', articlesPPData[0].ID_PP);
        return query;
      }
    ).get().toPromise();

    const articlesPPDataToDelete = snapshot.docs.map(doc => doc.data());

    articlesPPDataToDelete.forEach(async doc => {
      await this.af.collection(this.articlesPPCollectionName).doc(`${doc.ID_PP}-${doc.CODIGO_EAN}`).delete();
    });

    articlesPPData.forEach(async article => {
      await this.af.collection(this.articlesPPCollectionName).doc(`${article.ID_PP}-${article.CODIGO_EAN}`).set({
        ...article,
        OPERADOR: "O",
        ORDEN: 1
      });
    })
  }

}
