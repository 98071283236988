export enum PisoRoutes {
  USER = 'user',
  OBJECTIVES = 'objectives',
  MANAGEMENT = 'management',
  USERS_ROLE = 'users-role',
  MANAGEMENT_PERSONAL_WEIGHTS = 'management-personal-weights',
  MANAGEMENT_PILLARS = 'management-pillars',
  LIST_STAND_PDM = 'list-stand-pdm',
  EMIT_RRHH = 'emit-rrhh',
  PILLARS = 'pillars'
}
