import { Product } from '@common/models/product.interface';
import { Brand } from '@common/interfaces/brand.interface';
import { ProductsService } from '@common/services/products/products.service';
import {
  switchMap,
  defaultIfEmpty,
  map,
  catchError,
  tap
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, pipe, zip, of, from } from 'rxjs';
import { PisoPillarsCab } from '@common/interfaces/piso-pillars-cab.interface';
import { firestore } from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class PisoPillarsService {
  public static readonly collectionName = 'PISO_PILARES_CAB';

  constructor(
    private af: AngularFirestore,
    private productsService: ProductsService
  ) {}

  public static comparePisoPillarsCab(
    s1: PisoPillarsCab,
    s2: PisoPillarsCab
  ): boolean {
    return s1 && s2 ? s1.ID_PILAR === s2.ID_PILAR : s1 === s2;
  }

  /**
   * Metodos para obtener los datos de gestion piso
   */
  public getPilares(idBrand: number, date: Date): Observable<PisoPillarsCab[]> {
    return this.af
      .collection<PisoPillarsCab>(PisoPillarsService.collectionName, ref =>
        ref
          .where('ID_MARCA', '==', idBrand)
          .where('ANIO', '==', date.getFullYear())
          .where('MES', '==', date.getMonth() + 1)
      )
      .valueChanges()
      .pipe(
        switchMap(dataPillars =>
          zip(
            ...dataPillars.map(dp =>
              this.af
                .collection<{ CODIGO_EAN: string; ID_PILAR: number }>(
                  `PISO_PILARES`,
                  ref => ref.where('ID_PILAR', '==', dp.ID_PILAR)
                )
                .valueChanges()
                .pipe(
                  switchMap(pisoPillars =>
                    zip(
                      ...pisoPillars.map(pp =>
                        this.productsService
                          .getByEAN(pp.CODIGO_EAN)
                          .pipe(catchError(() => of(null)))
                      )
                    ).pipe(
                      defaultIfEmpty([]),
                      map(products => products.filter(p => !!p)),
                      map(products => ({
                        ...dp,
                        products
                      }))
                    )
                  )
                )
            )
          ).pipe(defaultIfEmpty([]))
        )
      );
  }

  /**
   * Metodo para modificar la descripcion de un Pilar existente
   * @param idPillars id del pillars a modificar
   * @param description nueva descripcion a modificar
   */
  public setPillars(idPillars: number, description: string): Observable<void> {
    return from(
      this.af
        .collection<PisoPillarsCab>(PisoPillarsService.collectionName)
        .doc(idPillars.toString())
        .update({ DESCRIPCION: description })
    );
  }

  /**
   * Metodo para crear un nuevo Pillars
   * @param description nueva descripcion a modificar
   */
  public addPillars(
    description: string,
    date: Date,
    brand: Brand
  ): Promise<void> {
    const pisoDoc = this.af.doc<{ UltimoValor: number }>(
      'SECUENCIAS/PISO_PILARES_CAB'
    );

    return pisoDoc
      .update({
        UltimoValor: (firestore.FieldValue.increment(1) as unknown) as number
      })
      .then(() => pisoDoc.get().toPromise())
      .then(doc =>
        this.af
          .doc<PisoPillarsCab>(
            `${PisoPillarsService.collectionName}/${doc.data().UltimoValor}`
          )
          .set({
            ANIO: date.getFullYear(),
            DESCRIPCION: description,
            ID_MARCA: brand.ID_MARCA,
            ID_PILAR: doc.data().UltimoValor,
            IMPORTE: null,
            MES: date.getMonth() + 1,
            PORCENTAJE: null,
            UNIDADES: null
          })
      );
  }

  /**
   * Metodo para eliminar un pillar a partir de su ID_PILAR
   * @param idPillar id del pillar
   */
  public removePillars(idPillars: number): Observable<void> {
    return from(
      this.af
        .collection<PisoPillarsCab>(PisoPillarsService.collectionName)
        .doc(idPillars.toString())
        .delete()
    );
  }

  /**
   * Metodo para eliminar un producto de un pilar
   * @param idPillar id del pillar a que se quiere borrar
   * @param codEAN codigo EAN del producto
   */
  public removeProductPillar(idPillar: number, codEAN: string) {
    return this.af.doc(`PISO_PILARES/${idPillar}-${codEAN}`).delete();
  }

  /**
   * Metodo para añadir un producto a un pilar
   * @param products iarray de los productos para añadir al pillar
   * @param idPillar id del pillar a que se quiere borrar
   */
  public addProductPillar(products: Product[], idPillar: number) {
    return products.forEach(product =>
      this.af
        .collection<any>(`PISO_PILARES`)
        .doc(`/${idPillar}-${product.CODIGO_EAN}`)
        .set({ CODIGO_EAN: product.CODIGO_EAN, ID_PILAR: idPillar })
    );
  }

  /**
   * getPillarById
   */
  public getPillarById(pillarId: number): Observable<PisoPillarsCab> {
    return this.af.doc<PisoPillarsCab>(`${PisoPillarsService.collectionName}/${pillarId}`).get()
      .pipe(map(docSnapshot => docSnapshot.data()));
  }

  /**
   * getAllPillarsCab
   */
  public getAllPillarsCab(): Observable<PisoPillarsCab[]> {
    return this.af.collection<PisoPillarsCab>(`${PisoPillarsService.collectionName}`).get()
      .pipe(map(snapshot => snapshot.docs.map(doc => doc.data())));
  }
}
