export enum TiersRoutes {
  CATALOGO = 'catalogo',
  PROMOCIONES = 'promociones',
  LISTADOPROMOCIONES = 'listadopromociones',
  IMPORTARPRECIOSATALAYA = 'importarpreciosatalaya',
  FUSIONSEPARACION = 'fusionseparacion',
  SEPARACIONHIBRIDACIONSTANDS = 'sephibstands',
  GESTIONUSUARIOS = 'gestionusuarios',
  BUSCARUSUARIO = 'buscarusuario',
  NEWUPDATEUSER = 'nuevoactualizarusuario',
  NEWUSER = 'altausuario',
  BUSCARCENTRO = 'buscarcentro',
  GESTIONCENTROSTAND = 'gestioncentrostand',
  NEWCENTERSTAND = 'altacentrostand'
}
