export enum CounselorRoutes {
  FIDELITY = 'fidelity',
  MERGE_CONSUMERS = 'merge-consumers',
  STAND_SALES = 'stand-sales',
  PERSONALDATA = 'personal-data',
  MANAGEMENT = 'management',
  MYDATA = 'my-data',
  STATISTICS = 'statistics',
  PROMOCIONESLIST = 'promocioneslist'
}
